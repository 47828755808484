<template>
	<div>
		<center-header :fetched-data="fetchedData" :links="links" />

		<div v-for="(block, index) in fetchedData.blocks" :key="index">
			<component
				:is="block.type"
				:ref="block.type"
				:index="index"
				:block="block"
				:content="block.content"
				:fetched-data="fetchedData"
				:static_content="block.static_content"
				:block_title="block.block_title"
			/>
		</div>
		<main-footer />
	</div>
</template>

<script>
import panels from '~/mixins/panels';
import createJivoMixin from '~/mixins/createJivoMixin';

import CenterHeader from '~/components/layouts/headers/CenterHeader.vue';
import PanelPartnersList from '~/components/dev/PanelPartnersList.vue';
import PanelReview from '~/components/panels/PanelMedia/PanelReview.vue';
import PanelDigitSteps from '~/components/static/PanelDigitSteps.vue';
import MainFooter from '~/components/layouts/footers/MainFooter.vue';
import makeCanonicalLink from '~/utils/seo/makeCanonicalLink';
import links from './links';

export default {
	name: 'CampusPage',
	components: {
		CenterHeader,
		PanelPartnersList,
		PanelReview,
		PanelDigitSteps,
		MainFooter,
	},
	mixins: [panels, createJivoMixin('fetchedData.consultation_chat_code')],
	async asyncData({ app }) {
		const { $pagesApi } = app;
		const fetchedData = await $pagesApi.openPrograms();
		return { fetchedData };
	},
	data() {
		return {
			fetchedData: [],
			links,
		};
	},
	head() {
		return {
			title: this.fetchedData.meta_title,
			link: [
				{
					rel: 'canonical',
					hid: 'canonical',
					href: makeCanonicalLink(this.$route.path),
				},
			],
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: this.fetchedData.meta_indexing,
				},
				{
					name: 'description',
					hid: 'description',
					content: this.fetchedData.meta_description,
				},
				// Open Graph
				{
					name: 'og:title',
					hid: 'og:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'og:description',
					hid: 'og:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'og:type',
					hid: 'og:type',
					content: 'website',
				},
				{
					name: 'og:image',
					hid: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},
				// Twitter Card
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:site',
					content: '@nuxt_js',
				},
				{
					name: 'twitter:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'twitter:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'twitter:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},

				{
					name: 'image',
					property: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},
				{
					name: 'author',
					content: 'SKOLKOVO',
				},
			],
		};
	},
	computed: {
	},
};
</script>
