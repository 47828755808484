const links = [
	{
		path: '/open-programs/',
		home: true,
	},
	{
		path: '/navigator/programmes/?typologies=1&taxonomy_projects=8&meta=1',
		name: 'Поиск программы',
		active: true,
	},
	{
		path: '/open-programs/op-prepodavateli/',
		name: 'Преподаватели ',
		active: true,
	},
	{
		path: '/open-programs/op-video/',
		name: 'Видео',
		active: true,
	},
];

export default links;
